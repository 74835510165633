.swipe-area {
    width: 100%;
    height: 400px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
  }
  
  .swipe-area span {
    font-size: 32px;
    margin: 0 10px;
  }
  